<template>
  <v-row align="center">
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ edit ? "Edit" : "Add" }} User</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form lazy-validation autocomplete="off">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="user.first_name"
                    outlined
                    dense
                    hide-details
                    :error="$v.user.first_name.$error"
                    ><template v-slot:label>
                      First Name <span class="text-danger">*</span>
                    </template></v-text-field
                  >
                  <span class="text-danger" v-if="$v.user.first_name.$error">
                    First Name is required
                  </span>
                  <span class="text-danger" v-if="errors.name">
                    {{ errors.name[0] }}
                  </span>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="user.last_name"
                    outlined
                    dense
                    hide-details
                    :error="$v.user.last_name.$error"
                    ><template v-slot:label>
                      Last Name <span class="text-danger">*</span>
                    </template></v-text-field
                  >
                  <span class="text-danger" v-if="$v.user.last_name.$error">
                    Last Name is required
                  </span>
                  <span class="text-danger" v-if="errors.name">
                    {{ errors.name[0] }}
                  </span>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="user.email"
                    outlined
                    dense
                    hide-details
                    :error="$v.user.email.$error"
                    ><template v-slot:label>
                      E-mail <span class="text-danger">*</span>
                    </template></v-text-field
                  >
                  <span class="text-danger" v-if="$v.user.email.$error">
                    Email is required
                  </span>
                  <span class="text-danger" v-if="errors.email">
                    {{ errors.email[0] }}
                  </span>
                </v-col>

                <v-col cols="12" md="6" v-if="!edit">
                  <v-text-field
                    v-model="user.password"
                    :type="show ? 'text' : 'password'"
                    hint="At Least 8 Characters"
                    outlined
                    dense
                    hide-details
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show = !show"
                    :error="$v.user.password.$error"
                    ><template v-slot:label>
                      Password <span class="text-danger">*</span>
                    </template></v-text-field
                  >
                  <span class="text-danger" v-if="$v.user.password.$error">
                    Password is required
                  </span>
                  <span class="text-danger" v-if="errors.password">
                    {{ errors.password[0] }}
                  </span>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="user.address_line_1"
                    outlined
                    dense
                    hide-details
                    :error="$v.user.address_line_1.$error"
                    ><template v-slot:label>
                      Address Line 1 <span class="text-danger">*</span>
                    </template></v-text-field
                  >
                  <span
                    class="text-danger"
                    v-if="$v.user.address_line_1.$error"
                  >
                    Address Line 1 is required
                  </span>
                  <span class="text-danger" v-if="errors.address_line_1">
                    {{ errors.address_line_1[0] }}
                  </span>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="user.address_line_2"
                    outlined
                    dense
                    hide-details
                    ><template v-slot:label>
                      Address Line 2
                    </template></v-text-field
                  >
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="user.mobile_no"
                    label="Mobile Number"
                    outlined
                    dense
                    hide-details
                    :error="$v.user.mobile_no.$error"
                    ><template v-slot:label>
                      Mobile Number <span class="text-danger">*</span>
                    </template></v-text-field
                  >
                  <span class="text-danger" v-if="$v.user.mobile_no.$error">
                    Mobile Number is required
                  </span>
                  <span class="text-danger" v-if="errors.mobile_no">
                    {{ errors.mobile_no[0] }}
                  </span>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="user.mobile_no"
                    label="Mobile Number"
                    outlined
                    dense
                    hide-details
                    :error="$v.user.mobile_no.$error"
                    ><template v-slot:label>
                      Mobile Number <span class="text-danger">*</span>
                    </template></v-text-field
                  >
                  <span class="text-danger" v-if="$v.user.mobile_no.$error">
                    Mobile Number is required
                  </span>
                  <span class="text-danger" v-if="errors.mobile_no">
                    {{ errors.mobile_no[0] }}
                  </span>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="user.phone_no"
                    label="Phone Number"
                    outlined
                    dense
                    hide-details
                    prepend-inner-icon="call"
                    ><template v-slot:label>
                      Phone Number
                    </template></v-text-field
                  >
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="user.office_no"
                    label="Office Number"
                    outlined
                    dense
                    hide-details
                    prepend-inner-icon="call"
                    ><template v-slot:label>
                      Office Number
                    </template></v-text-field
                  >
                </v-col>

                <v-col cols="12" md="6">
                  <v-file-input
                    class="mb-3"
                    @change="previewImages()"
                    v-model="user.imageFile"
                    label="User Image"
                    accept="image/*"
                    small-chips
                    prepend-icon=""
                    prepend-inner-icon="mdi-file"
                    hide-details
                    outlined
                    dense
                  ></v-file-input>
                </v-col>

                <v-col cols="12" md="12">
                  <v-img
                    class="w-100 max-h-300px"
                    contain
                    :src="previewImage"
                  ></v-img>
                </v-col>

                <v-col cols="12" sm="6">
                  Status
                  <v-switch
                    v-model="user.is_active"
                    :label="user.is_active ? 'Active' : 'Inactive'"
                    outlined
                    dense
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" dark @click="createOrUpdate">
            {{ edit ? "Update" : "Save" }}
          </v-btn>
          <v-btn color="red darken-1" dark @click="close"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import UserService from "../../../service/user/UserService";
import { validationMixin } from "vuelidate";
import {
  email,
  required,
  minLength,
  numeric,
  maxLength,
  requiredIf
} from "vuelidate/lib/validators";

const userService = new UserService();

export default {
  name: "CreateAndUpdate",
  mixins: [validationMixin],
  data() {
    return {
      show: false,
      dialog: false,
      edit: false,
      user: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        imageFile: null,
        mobile_no: "",
        phone_no: "",
        office_no: "",
        is_active: true
      },
      errors: [],
      previewImage: require("@/assets/images/no-image.png")
    };
  },
  validations: {
    user: {
      first_name: { required },
      last_name: { required },
      email: { required, email },
      password: {
        required: requiredIf(function() {
          return !this.edit;
        }),
        minLength: minLength(8)
      },
      mobile_no: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      address_line_1: { required }
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.edit = false;
      this.reset();
    },

    createUser() {
      this.dialog = true;
      this.edit = false;
      this.reset();
    },

    editUser(user) {
      this.dialog = true;
      this.edit = true;
      this.reset();
      this.user = Object.assign({}, this.user, user);
      if (this.user.image_path) {
        this.previewImage = this.user.image_path.original;
      }
    },

    createOrUpdate() {
      this.$v.user.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (!this.editUser) {
          this.create();
        } else {
          // this.$delete(this.user, "password");
          this.update();
        }
        this.close();
      }
    },
    reset() {
      this.$v.$reset();
      this.previewImage = require("@/assets/images/no-image.png");
      this.user = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        address_line_1: "",
        address_line_2: "",
        imageFile: null,
        mobile_no: "",
        phone_no: "",
        office_no: "",
        is_active: true
      };
    },
    create() {
      let formData = this.setFormData();
      userService
        .store(formData)
        .then(response => {
          if (response.data.status == "OK") {
            this.$snotify.success("Add user Successfully");
            this.$emit("refresh");
            this.reset();
          } else {
            this.$snotify.error("Something Went Wrong");
          }
        })
        .catch(err => {
          if (err.response.status == 422) {
            this.errors = err.response.data.errors;
          }
          setTimeout(() => {
            this.errors = [];
          }, 3000);
        });
    },
    update() {
      let formData = this.setFormData();
      userService.update(this.user.id, formData).then(response => {
        if (response.data.status == "OK") {
          this.$snotify.success("Add user Successfully");
          this.$emit("refresh");
        } else {
          this.$snotify.error("Something Went Wrong");
        }
      });
    },
    previewImages() {
      if (this.user.imageFile != null) {
        this.previewImage = URL.createObjectURL(this.user.imageFile);
      } else {
        this.previewImage = require("@/assets/images/no-image.png");
      }
    },
    setFormData() {
      let formData = new FormData();
      for (let key in this.user) {
        if (
          key === "imageFile" &&
          this.user[key] != null &&
          this.user[key] !== undefined
        ) {
          formData.append("imageFile", this.user[key]);
        } else {
          if (this.user[key]) {
            formData.append(key, this.user[key]);
          }
        }
      }
      return formData;
    }
  }
};
</script>
